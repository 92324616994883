import React from 'react';

export default function ShippingLabel({ orderId, type, width = '100%', rotate = undefined, trackingNumber }) {
  const handleFirstImageError = e => {
    e.target.onerror = handleLastImageError;
    e.target.src = `https://app.boothbymail.com/api/orders/${orderId}/label/${type}`;
  };
  const handleLastImageError = e => {
    e.target.onerror = null;
    e.target.src = `https://via.placeholder.com/1040x594.png?text=The+${type}+shipping+label+for+order+${orderId}+hasnt+been+made+yet`;
  };
  const src = `https://s3.us-east-1.amazonaws.com/labels.boothbymail.com/${
    process.env.REACT_APP_SITE_ENV
  }/${orderId}/${type}?trackingNumber=${trackingNumber}&u=${new Date().getTime()}`;
  const handleImageClick = (e, data) => {
    window.open(e.target.src, '_blank');
  };
  return (
    <img
      src={src}
      onError={handleFirstImageError}
      width={width}
      style={rotate ? { transform: 'rotate(90deg)' } : {}}
      onClick={handleImageClick}
    />
  );
}
